// green from limeinst.com ...this color fucking sucks
//#005eb6 wier blue
//

$ranger: #fdcc09;
$lime: #00d604;
$deepwell: #ce0e2d;
$liberty: rgb(225, 49, 38);
$spm: #ffa711;
$terra: rgba(0, 116, 200, 0.76);

$rangerbg: rgba(20, 20, 20, 1);
$limebg: rgba(20, 20, 20, 1);
$deepwellbg: #101820;
$libertybg: rgba(20, 20, 20, 1);
$spmbg: rgba(20, 20, 20, 1);
$terrabg: rgba(20, 20, 20, 1);

$company: $lime;
$companybg: $limebg;
//@import url('https://use.typekit.net/eiv6kkf.css');
// Dark theme
$primary-color: $company !default; //#43a047
$primary-color-light: lighten($primary-color, 15%) !default;
$primary-color-dark: darken($primary-color, 15%) !default;
$black: rgba(20, 20, 20, 1);

$secondary-color: $company; //rgba(128,132,127,1);
// $success-color: color("green", "base") !default;
// $error-color: color("red", "base") !default;
$background-color: $companybg; //#303030;
$tile-background-color: lighten($background-color, 5%);
$header-background-color: $black; //rgba(0,0,0,.8);
$sidebar-color: #444;
$border-color: rgba(255, 255, 255, 0.2); //#555

//@import url('https://fonts.googleapis.com/css?family=Raleway');
// @font-face {
//   font-family: 'Helvetica';
//   src: url('./font/Gotham-Book.otf');
// }
$font-family: "Proxima Nova Thin"; //'Gotham', 'Raleway';
$font-family-bold: "Proxima Nova Semibold"; //'Gotham', 'Raleway';
$font-size: 1rem; // 1 rem = 10px
$line-height: normal;
$font-color: rgba(255, 255, 255, 0.8);
$button-font-color: #333;
$header-color: rgba(255, 255, 255, 0.8); //font color
$active-tab-color: rgba(0, 0, 0, 0.8);

$link-color: lighten($primary-color, 0%);
$body-link-color: $primary-color; //
$header-link-color: white;

//Light Theme
// $primary-color: #005eb6 !default; // rgb(112,190,67)
// $primary-color-light: lighten($primary-color, 15%) !default;
// $primary-color-dark: darken($primary-color, 15%) !default;
//
// $secondary-color: #ed8b00;//rgba(128,132,127,1); //unused for now
// $success-color: color("green", "base") !default;
// $error-color: color("red", "base") !default;
//
// $background-color: 	#FFFFFF; //rgba(255,255,255,.85);
// $tile-background-color: rgba(220,220,220,.25);
// $header-background-color: #555;
// $sidebar-color: rgba(255,255,255,1);
// $border-color: rgba(0,0,0,.70); //#555
//
// @import url('https://fonts.googleapis.com/css?family=Raleway');
// @font-face {
//   font-family: 'Gotham';
//   src: url('./font/Gotham-Book.otf');
// }
// $font-family: 'Gotham', 'Raleway';
// $font-size: 10px; // 1 rem = 10px
// $line-height: 1.5rem;
// $button-font-color: rgba(255,255,255,.8);
// $font-color: rgba(0,0,0,.8);
// $active-tab-color: rgba(0,0,0,.8);
// $header-color: rgba(255, 255, 255, .8); //font color
//
// $link-color: lighten($primary-color,0%);
// $body-link-color: $primary-color; //
//
// $header-background-color: $primary-color;//#555;
// $header-link-color: white;

:export {
	fontColor: $font-color;
	borderColor: $border-color;
	tileBackground: $tile-background-color;
}
